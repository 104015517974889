// import external dependencies
//import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import templateLanding from './routes/templateLanding';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  templateLanding,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
