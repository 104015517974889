import Gmap from './gmap';
import Opmap from './opmap';

class MapFactory {

  constructor(){

  }

  getMap($container){

    var mapObject = null;

    switch ($container.data('maptype')) {
      case 'gmap':
        mapObject = new Gmap($container);
        break;
      case 'openstreet':
        mapObject = new Opmap($container);
        break;
    }
    return mapObject;

  }

}

export default MapFactory;
