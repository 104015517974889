export default {
  finalize() {

    const selectGym = document.getElementById('selectGym');

    if(selectGym) {
      const selectGymChange = () => {
        let value = selectGym.options[selectGym.selectedIndex].value;
        document.getElementById('buttonGym').href= value;
      }

      selectGym.addEventListener('change', selectGymChange);
    }

  },
}
