/*eslint no-console: [0, { allow: ["log"] }] */
import {tns} from 'tiny-slider/src/tiny-slider'
import fitvids from 'fitvids';
import baguetteBox from 'baguettebox.js/src/baguetteBox';
// eslint-disable-next-line
import Maps from '../classes/mapjs';
import MapFactory from '../classes/mapfactory';

export default {
  finalize() {


    const mFactory = new MapFactory();
    $('.maps__map').each(function () {
      mFactory.getMap($(this).parent());
    });

    const $meganav = $('#megamenu-container .nav-column');

    const $allLinks = $meganav.find('.nav-link');

    const $allLinkZero = $('.level-0 .nav-link');

/*
    $allLinks.each(function( index ) {


    });
*/

    $allLinks.click(function (e) {

      let $this = $(this);


      if($this.hasClass('prevented')) {
        e.preventDefault();
      }

      if($this.hasClass('nav-link--is-anchor')){

        const $currentPathname = window.location.pathname;

        const $relativePathname = $this.data('relativehref');

        if ($currentPathname === $relativePathname) {
          //$('#megaToggler').click();

          setTimeout(function (){
            window.location.reload()
          },150)

        }
      }

      let $currentCol = $this.closest('.nav-column');

      if ($currentCol.hasClass('level-0')) {
        $('.tab-pane').removeClass('active show');
      }

      $allLinks.removeClass('show active');
      //allContent.removeClass('show active');
      $this.tab('show');

      $allLinkZero.addClass('unactive');

      $this.removeClass('unactive')
      $this.addClass('active')

    });

    const body = document.getElementsByTagName('BODY')[0];
    const header = document.getElementById('header');

    const toggle = document.getElementById('megaToggler');

    const megaMenuContainer = document.getElementById('megamenu-container');
    if (megaMenuContainer) {

      const megaMenuInner = document.getElementById('megamenu-inner');

      megaMenuInner.style.marginTop = header.offsetHeight + 'px';
      megaMenuInner.style.minHeight = 'calc( 100vh - ' + header.offsetHeight + 'px)';

      const toggleNav = () => {
        body.classList.toggle('body-showmenu');
        toggle.classList.toggle('toggle-showmenu');
        megaMenuContainer.classList.toggle('megamenu-showmenu');
        let toggleAria = toggle.getAttribute('aria-expanded');

        if (toggleAria == 'true') {
          toggleAria = 'false'
        } else {
          toggleAria = 'true'
        }
        toggle.setAttribute('aria-expanded', toggleAria);
      }

      toggle.addEventListener('click', toggleNav);
    }

    // ------- TNS CAROUSEL --------

    function zeroPad(num, places) {
      let zero = places - num.toString().length + 1;
      return Array(+(zero > 0 && zero)).join('0') + num;
    }

    const tnsCarousel = document.querySelectorAll('.tns-carosello > .tns-carousel-inner');

    tnsCarousel.forEach(slider => {

      const currentSlider = slider.closest('.tns-carosello');
      const carouselOneSlide = currentSlider.classList.contains('carosello-1-slide');
      const carouselAutoplay = currentSlider.classList.contains('carosello-autoplay');
      const carouselOneSlideCentered = currentSlider.classList.contains('carosello-1-slide-centered');
      const carouselNogutter = currentSlider.classList.contains('carosello-no-gutter');
      const carouselNodrag = currentSlider.classList.contains('carosello-no-drag');
      const carouselNoloop = currentSlider.classList.contains('carosello-no-loop');

      const totalSlides = currentSlider.querySelectorAll('.tns-carousel-item').length;

      const currentSlideIndicator = totalSlides > 1 ? currentSlider.querySelectorAll('.tns-controls > .tns-indicators .current-slide')[0] : currentSlider.querySelectorAll('.tns-controls > .tns-indicators .current-slide');
      const divTotalSlidesCount = totalSlides > 1 ? currentSlider.querySelectorAll('.tns-controls > .tns-indicators .total-slides')[0] : currentSlider.querySelectorAll('.tns-controls > .tns-indicators .total-slides');

      const tnsSlider = tns({
        onInit: (tnsSlider) => {
          if (typeof currentSlideIndicator !== 'undefined') {
            currentSlideIndicator.innerHTML = zeroPad(tnsSlider.displayIndex, 2);
            divTotalSlidesCount.innerHTML = zeroPad(tnsSlider.slideCount, 2);
          }
        },
        container: slider,
        navPosition: 'bottom',
        nav: false,
        autoplay: carouselAutoplay,
        mouseDrag: !carouselNodrag,
        loop: !carouselNoloop,
        autoplayButtonOutput: false,
        gutter: carouselOneSlide ? 0 : carouselNogutter ? 0 : 30,
        prevButton: totalSlides > 1 ? currentSlider.querySelectorAll('.tns-controls .prev')[0] : false,
        nextButton: totalSlides > 1 ? currentSlider.querySelectorAll('.tns-controls .next')[0] : false,
        mode: carouselOneSlide ? 'gallery' : 'carousel',
        items: 1,
        responsive: {
          321: {
            items: carouselOneSlide ? 1 : carouselOneSlideCentered ? 1 : 4,
            fixedWidth: carouselOneSlide ? false : carouselOneSlideCentered ? false : 250,
            center: carouselOneSlideCentered ? true : false,
            autoWidth: carouselOneSlide ? false : carouselOneSlideCentered ? false : true,
          },
        },
      });
      if (currentSlider.classList.contains('twoindex')) {
        currentSlider.querySelectorAll('.tns-controls .prev')[0].classList.add('invisible');
      }
      tnsSlider.events.on('indexChanged', (tnsSlider) => {
        if (currentSlider.classList.contains('twoindex')) {
          if (tnsSlider.displayIndex === 2) {
            currentSlider.querySelectorAll('.tns-controls .next')[0].classList.add('invisible');
            currentSlider.querySelectorAll('.tns-controls .prev')[0].classList.remove('invisible');
          } else {
            currentSlider.querySelectorAll('.tns-controls .prev')[0].classList.add('invisible');
            currentSlider.querySelectorAll('.tns-controls .next')[0].classList.remove('invisible');
          }
        }
        if (typeof currentSlideIndicator !== 'undefined') {
          currentSlideIndicator.innerHTML = zeroPad(tnsSlider.displayIndex, 2);
        }
      });
    });

    const getOffsetTop = (element) => {
      let offsetTop = 0;
      while (element) {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
      }
      return offsetTop;
    }

    const sliderIndicatorsTop = (sliderId) => {
      const Slider = document.getElementById(sliderId);
      const caption = Slider.querySelectorAll('.tns-caption')[0];
      let X = getOffsetTop(caption);
      const sliderIndicators = Slider.querySelectorAll('.tns-indicators')[0];
      if (sliderIndicators) {
        if (window.innerWidth > 425) {
          sliderIndicators.style.top = (X - sliderIndicators.offsetHeight) / 2 + 'px';
        } else {
          sliderIndicators.style.top = 'inherit';
          sliderIndicators.style.bottom = '32px';
        }
      }
    }

    const sliders = document.querySelectorAll('.mainCarousel');

    sliders.forEach((slider) => {

      const sliderId = slider.id;

      sliderIndicatorsTop(sliderId);


      window.addEventListener('resize', sliderIndicatorsTop(sliderId));
    });


    // ------- fitvids --------

    fitvids();


    // ------- gallery --------
    baguetteBox.run('.galleria', {
      captions: function (element) {

        var altText = '';
        if (element.getElementsByTagName('img').length > 0) {
          altText = element.getElementsByTagName('img')[0].alt;
        }

        return altText;
      },
    });

    // ------- input file --------
    $(document).on('change', '.custom-file-input', function () {
      let fileName = $(this).val().replace(/\\/g, '/').replace(/.*\//, '');
      $(this).parent('.custom-file').find('.custom-file-label >.text-label').text(fileName);
    });
    // ------- input file --------


    const selectGyms = document.querySelectorAll('.selectGym');

    if (selectGyms) {
      selectGyms.forEach((selG) => {

        selG.addEventListener('change', function (selG) {

          const btn = selG.currentTarget.parentElement.querySelector('.buttonGym')
          const value = selG.currentTarget.options[selG.currentTarget.selectedIndex].value;
          //if (btn.classList.contains('buttonGym')) {
            btn.href = value;
          //}

        });
      });
    }

    /** TABS **/

    const sectionTabs = document.querySelectorAll('.section-tabs');



    // ------- TABS --------

    //codice jquery per bootstrap, non rimuovere

    $('.tabs__nav >.nav > .nav-item >.nav-link').on('click', function (e) {
      e.preventDefault();

      let $this = $(this);
      let tabtitle = $this.data('target');
      tabtitle = tabtitle.substring(1, tabtitle.length);
      let tabTitlediv = $('.tabs__title-wrapper').find('#title-' + tabtitle);
      $this.tab('show')
      $('.tabs__title-wrapper').find('.tab-title').removeClass('show active');
      tabTitlediv.tab('show')
    })


    sectionTabs.forEach((sectionTab) => {

      const navTabs = sectionTab.querySelector('.nav-tabs');

      const tabsLinks = navTabs.querySelectorAll('.nav-item .nav-link');

      tabsLinks.forEach((tblink) => {

        let tabNameActive = sectionTab.querySelector('.nav-link.active').querySelector('.title-tab').textContent.toLowerCase().replace(' ', '');

        tblink.addEventListener('click', (tbclicked) => {

          tabNameActive = tbclicked.currentTarget.querySelector('.title-tab').textContent.toLowerCase().replace(' ', '');

          history.pushState({page: tabNameActive}, tabNameActive, '?tab=' + tabNameActive);

        });

      })

      function getParams(location) {
        const searchParams = new URLSearchParams(location.search);
        return {
          tab: searchParams.get('tab'),
        };
      }

      const params = getParams(window.location);

      const tabToClick = sectionTab.querySelector('.nav-link--' + params.tab);

      if (params.tab && tabToClick) {
        tabToClick.click();
      }
    })



  },
};
