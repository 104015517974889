/* eslint-disable */
import Maps from './mapjs';

class Opmap extends Maps {

  constructor($container){

    super($container);

  }

  initMap($markers){

    // set up the map
    map = new L.Map('mapid');

    // create the tile layer with correct attribution
    var osmUrl='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    var osmAttrib='Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors';
    var osm = new L.TileLayer(osmUrl, {minZoom: 0, maxZoom: 18, attribution: osmAttrib});

    map.setView(new L.LatLng(45.5463, 11.5503),12);
    map.addLayer(osm);

    map.scrollWheelZoom.disable();

    var greenMarker = L.icon({
      iconUrl: '',
      //shadowUrl: 'leaf-shadow.png',

      iconSize:     [50, 50], // size of the icon
      //shadowSize:   [50, 64], // size of the shadow
      //iconAnchor:   [0, 0], // point of the icon which will correspond to marker's location
      //shadowAnchor: [4, 62],  // the same for the shadow
      popupAnchor:  [0, -25] // point from which the popup should open relative to the iconAnchor
    });

    $markers.each(function() {

      var $lng = $(this).data('lng');
      var $lat = $(this).data('lat');
      var $popup = $(this).html();
      $popup = $popup.trim();

      //$markersarr.push([$popup, $lat, $lng]);

      marker = new L.marker([$lat,$lng], {icon: greenMarker})
        .bindPopup($popup)
        .addTo(map);

    })

    map.on('click', function() {
      if (map.scrollWheelZoom.enabled()) {
        map.scrollWheelZoom.disable();
      }
      else {
        map.scrollWheelZoom.enable();
      }
    })
  }
}

export default Opmap;
