class Maps {

  constructor($container){

    this.markers = $container.find('.marker');
    this.$container = $container;

    this.initMap(this.markers);

  }

  initMap(){

  }

  addMarker(){

  }

  centerMap(){

  }

}

export default Maps;
