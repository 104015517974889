/* eslint-disable */
import Maps from './mapjs';

class Gmap extends Maps {

  constructor($container){

    super($container);

  }

  initMap($markers){

    var self = this;

    let args = {
      zoom		: 16,
      center		: new google.maps.LatLng(0, 0),
      mapTypeId	: google.maps.MapTypeId.ROADMAP,
      styles : [
        {
        "featureType": "administrative",
  "elementType": "labels.text",
  "stylers": [
      {
                "visibility": "on"
  }
  ]
  },
    {
    "featureType": "landscape",
    "elementType": "all",
    "stylers": [
      {
                "saturation": "-33"
    },
      {
      "lightness": "17"
      },
      {
      "color": "#E8EAF0"
      }
    ]
    },
    {
    "featureType": "poi",
    "elementType": "all",
    "stylers": [
      {
                "visibility": "off"
    },
      {
      "lightness": "-21"
      },
      {
      "weight": "1.75"
      }
    ]
    },
    {
    "featureType": "road",
    "elementType": "all",
    "stylers": [
      {
                "saturation": "-2"
    },
      {
      "lightness": "2"
      },
      {
      "gamma": "0.70"
      },
      {
      "weight": "1.16"
      }
    ]
    },
    {
    "featureType": "transit.station.rail",
    "elementType": "all",
    "stylers": [
      {
                "visibility": "simplified"
    },
      {
      "saturation": "-100"
      },
      {
      "lightness": "32"
      },
      {
      "gamma": "1.64"
      }
    ]
    },
    {
    "featureType": "water",
    "elementType": "all",
    "stylers": [
      {
                "visibility": "on"
    },
      {
      "saturation": "-29"
      },
      {
      "lightness": "15"
      }
    ]
    }
  ]
  };

    let map = new google.maps.Map( this.$container.find('.maps__map').get(0), args);

    map.markers = [];

    map.baloonType = this.$container.data('maptemplate');


    google.maps.event.addListener(map, "click", function() {

      if( map.baloonType === 'infowindow') {

        map.markers.forEach(function (marker,i) {
          marker.infowindow.close(true);
        });

      }
      else {
        $markers.removeClass('active');
      }

    });

    $markers.each(function(){
      self.addMarker($(this), map);
    });

    map.markers.forEach(function (marker, i) {
      setTimeout(function () {
        marker.setVisible(true);
      }, i * 50);
    });

    this.centerMap(map);

    return map;

  }

  addMarker($marker, map){

    var latlng = new google.maps.LatLng( $marker.attr("data-lat"), $marker.attr("data-lng") );

    var icon = {
      url: $marker.attr("data-marker"),
      scaledSize: new google.maps.Size(parseInt($marker.attr("data-w")), parseInt($marker.attr("data-h"))), // scaled size
      origin: new google.maps.Point(0,0)
      //anchor: new google.maps.Point(0, 0)
    }

    var marker = new google.maps.Marker({
      position	: latlng,
      map			: map,
      icon    : icon
    });

    marker.setVisible(false);
    marker.setAnimation(google.maps.Animation.DROP);

    map.markers.push( marker );

    if( $marker.html() !== '' )
    {


      if( map.baloonType === 'infowindow') {

        marker.infowindow = new google.maps.InfoWindow({
          content: '<div class="baloon">' + $marker.html() + '</div>'
        });

      }

      google.maps.event.addListener(marker, "click", function() {

        if( map.baloonType === 'infowindow') {

          map.markers.forEach(function (marker,i) {
            marker.infowindow.close(true);
          });

          marker.infowindow.open( map, marker );

        }
        else {
          $marker.parent().find('.marker').removeClass('active');
          $marker.addClass('active');
        }

      });
    }
  }

  centerMap(map){

    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each( map.markers, function( i, marker ){

      var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

      bounds.extend( latlng );

    });

    // only 1 marker?
    if( map.markers.length == 1 )
    {
      // set center of map
      map.setCenter( bounds.getCenter() );
      map.setZoom( 16 );
    }
    else
    {
      // fit to bounds
      map.fitBounds( bounds );
    }

  }
}

export default Gmap;
